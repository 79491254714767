<template>
  <div>
    <div class="status-box">
      <div>
        <DescItem :label="$t('7ae0ca6')">
          <span style="color: #999999">{{ data.code }}</span>
        </DescItem>
        <div class="status-bar" :style="{color: status2color[data.status]}">
          {{ STATUS[data.status] }}
        </div>
      </div>
      <div class="verticle-line"></div>
      <div class="step-box">
        <Step :data="data" />
      </div>
    </div>
    <Card>
      <template v-slot:header>{{ $t('9360f27') }}</template>
      <template v-slot:actions>
        <el-popconfirm
          v-if="showCancel"
          title="确认取消"
          @confirm="handleCancelClick"
        >
          <el-button
            slot="reference"
            style="
              height: 32px;
              padding: 8px 22px;
              border-color: rgba(0, 164, 255, 1);
            "
          >
            <span style="color: rgba(0, 164, 255, 1); font-size: 14px">
              {{ $t('db89f23') }}
            </span>
          </el-button>
        </el-popconfirm>
      </template>
      <DisplayCard :data="data" show-actions />
    </Card>
  </div>
</template>

<script>
import { Message } from "element-ui"

import Card from "@/components/Card"
import DescItem from "@/components/Descriptions/Item"
import Step from "./step"

import api from "@/api/content.lookup"

import DisplayCard from "../displayCard/detailCard"

import { STATUS } from '../constants'

const status2color = {
  SUBMIT: "rgba(250, 173, 20, 1)",
  QUERYING: "rgba(250, 173, 20, 1)",
  COMPLETED: "rgba(81, 197, 24, 1)",
  WITHDRAW: "rgba(153, 153, 153, 1)",
}

export default {
  name: "RequestDetail",
  components: {
    Card,
    DisplayCard,
    Step,
    DescItem,
  },
  data() {
    return {
      STATUS,
      status2color,
      data: {},
      showRepublish: false,
    }
  },
  computed: {

    showCancel() {
      return this.data.status === "SUBMIT"
    },
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    handleCancelClick() {
      api.cancel(this.data.id).then((res) => {
        if (res) {
          this.getDetail()
          Message({
            message: "取消成功",
            type: "success",
            duration: 5 * 1000,
            showClose: true,
          })
        }
      })
    },
    getDetail() {
      const id = this.$route.query.id
      if (id) {
        api.getDetail(id).then((res) => {
          this.data = res
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
.status-box {
  background: white;
  padding: 30px 0 30px 30px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.step-box {
  flex-grow: 1;
}
.verticle-line {
  height: 90px;
  border-right: 1px solid rgba(238, 238, 238, 1);
  margin-left: 30px;
}
.status-bar {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin-top: 16px;
}
</style>
