import i18n from "@/i18n"

export const status2color = {
  QUERYING: {
    bg: 'rgba(250, 173, 20, 0.07)',
    text: 'rgba(250, 173, 20, 1)',
  },
  SUBMIT: {
    bg: 'rgba(250, 173, 20, 0.07)',
    text: 'rgba(250, 173, 20, 1)',
  },
  COMPLETED: {
    bg: 'rgba(81, 197, 24, 0.07)',
    text: 'rgba(81, 197, 24, 1)',
  },
  WITHDRAW: {
    bg: 'rgba(153, 153, 153, 0.07)',
    text: 'rgba(153, 153, 153, 1)',
  },
  DEFAULT: { bg: 'rgba(0, 164, 255, 0.07)', text: 'rgba(0, 164, 255, 1)' },
}

export const STATUS = {
  get SUBMIT() { return i18n.t('已提交') },
  get TO_BE_COMMUNICATED() { return i18n.t('已提交') },
  get COMMUNICATED() { return i18n.t('已提交') },
  get QUERYING() { return i18n.t('f23aaeb') },
  get COMPLETED() { return i18n.t("c3c076c") },
  get CANCEL() { return i18n.t('0a82a95') },
}
