<template>
  <div class="display-card-box">
    <div class="display-card-header">
      <div v-if="showActions" class="action-box">
        <el-popconfirm
          v-if="allowDelete"
          :title="$t('8d1023d')"
          @confirm="handleDelete"
        >
          <i slot="reference" class="delete-icon icon"></i>
        </el-popconfirm>
        <i v-if="allowEdit" class="edit-icon icon" @click="handleEdit"></i>
      </div>
    </div>
    <div class="display-card-content">
      <el-row>
        <el-col
          v-for="(item, index) of descMetaData"
          :key="index"
          :span="item.span || 12"
        >
          <DescItem v-if="item.key === 'annex'" :label="item.label">
            <a
              v-if="data.annex"
              :href="data.annex"
              style="color: #00a4ff"
              :download="data.fileName"
            >
              <i
                class="el-icon-paperclip"
                style="color: grey; margin-right: 8px; font-size: 16px"
              ></i>{{ data.fileName }}
            </a>
            <span v-else>--</span>
          </DescItem>
          <DescItem
            v-else-if="item.type === 'radio'"
            :label="item.label"
            :link="data.link"
            :line-count="item.lineCount"
          >
            <el-radio-group :value="!!data[item.key]">
              <el-radio :label="true">{{ $t('99df41f') }}</el-radio>
              <el-radio :label="false">{{ $t('4e21644') }}</el-radio>
            </el-radio-group>
          </DescItem>
          <DescItem
            v-else
            :label="item.label"
            :link="data.link"
            :line-count="item.lineCount"
          >
            {{
              item.compute
                ? item.compute(data[item.key], data)
                : data[item.key] | textFilter
            }}
          </DescItem>
        </el-col>
      </el-row>
    </div>
    <div class="display-card-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import DescItem from "@/components/Descriptions/Item"

import { STATUS, status2color } from "../constants"

import { mapGetters } from "vuex"

export default {
  name: "DisplayCard",
  components: {
    DescItem,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    showActions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["areaTree", "areaMap", "dictionaries"]),
    color() {
      return status2color[this.data.status] || status2color["DEFAULT"]
    },
    urgentColor() {
      return this.data.urgentStatus === "EMERGENCY" ? "red" : ""
    },
    statusStr() {
      return STATUS[this.data.status]
    },
    allowDelete() {
      return this.data.status === "UNDER_REVIEW"
    },
    allowEdit() {
      return this.data.status === "UNDER_REVIEW"
    },
    descMetaData() {
      return [
        { label: this.$t('2eb91b0'), key: "name", span: 14 },
        { label: this.$t('c49f52a'), key: "unit", span: 10 },

        { label: this.$t('db8d921'), key: "phone", span: 14 },
        { label: this.$t('5bfb52e'), key: "email", span: 10 },

        { label: this.$t('f7f1545'), key: "country", span: 14 },
        { label: this.$t('cda23fb'), key: "department", span: 10 },

        { label: this.$t('98a7c9a'), key: "expectedCost", span: 14 },
        { label: this.$t('4c2e103'), key: "expectedTime", span: 10 },

        {
          label: this.$t('8a0f7e5'),
          key: "isItInvolved",
          span: 14,
          type: "radio",
        },
        {
          label: this.$t('a38c649'),
          key: "foreignInvestment",
          span: 10,
          type: "radio",
        },

        {
          label: this.$t('eb2a4b2'),
          key: "needNotarization",
          span: 14,
          type: "radio",
        },
        {
          label: this.$t('6f60d9e'),
          key: "needTranslation",
          span: 10,
          type: "radio",
        },
        {
          label: this.$t('06618ba'),
          key: "caseDescription",
          span: 24,
        },
        {
          label: this.$t('eb15d1f'),
          key: "demandDescription",
          span: 24,
        },
        { label: this.$t('71867bc'), key: "annex", span: 24 },
      ]
    }
  },
  methods: {
    handleDelete() {
      this.$emit("delete", this.data.id)
    },
    handleEdit() {
      this.$emit("edit", this.data.id)
    },
  },
}
</script>

<style scoped>
.display-card-box {
  border: 1px solid #e7edef;
  border-radius: 4px;
  padding: 20px 0;
  margin-bottom: 20px;
}

.display-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
}

.status-badge {
  width: 114px;
  height: 36px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  border-radius: 0 18px 18px 0;
}

.display-card-content {
  padding: 0 30px;
}

.icon.delete-icon {
  background: url("../../../../assets/common/delete.png");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}
.icon.edit-icon {
  background: url("../../../../assets/common/edit.png");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}
.icon {
  margin: 5px;
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.display-card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.action-button {
  color: rgba(0, 164, 255, 1);
  font-size: 14px;
  cursor: pointer;
}
</style>
