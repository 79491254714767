<template>
  <div class="custom-steps">
    <el-steps :active="step" align-center finish-status="success">
      <el-step :title="$t('5a41eb8')" class="digital-step">
        <template v-slot:icon> 1 </template>
        <template v-slot:description>
          <p class="time">{{ data.createTime }}</p>
        </template>
      </el-step>

      <el-step v-if="!isCanceled" icon="el-icon-remove" class="step-item" />

      <el-step v-if="!isCanceled" :title="$t('f23aaeb')" class="digital-step">
        <template v-slot:icon> 2 </template>
        <template v-slot:description>
          <p class="time">{{ data.startTime }}</p>
        </template>
      </el-step>

      <el-step v-if="!isCanceled" icon="el-icon-remove" class="step-item" />

      <el-step v-if="!isCanceled" :title="$t('c3c076c')" class="digital-step">
        <template v-slot:icon> 3 </template>
        <template v-slot:description>
          <p class="time">{{ data.endTime }}</p>
        </template>
      </el-step>

      <el-step v-if="isCanceled" :title="$t('0a82a95')" class="digital-step">
        <template v-slot:description>
          <p class="time">{{ data.cancelTime }}</p>
        </template>
      </el-step>
    </el-steps>
  </div>
</template>
<script>
export default {
  name: "Step",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    step() {
      switch (this.data.status) {
        case "CANCEL":
          return 0
        case "SUBMIT":
          return 2
        case "COMMUNICATED":
          return 2
        case "QUERYING":
          return 4
        case "COMPLETED":
          return 5
        default:
          return 2
      }
    },
    isCanceled() {
      return this.data.status === "CANCEL"
    },
  },
}
</script>

<style scoped lang="less">
.custom-steps {
  // padding: 20px;
  // margin-bottom: 20px;
  .step-item {
    /deep/ .el-step__title {
      color: #666666;
      font-weight: 600;
    }
    /deep/ .el-step__head.is-finish {
      /deep/ .el-step__title {
        color: #000000;
      }
    }
    /deep/ .el-step__icon {
      top: 8px;
      background: #f5fcff;
      width: 18px;
      height: 18px;
      /deep/ .el-step__icon-inner::before {
        content: "";
        display: block;
        background: rgba(0, 164, 255, 1);
        border-radius: 6px;
        width: 10px;
        height: 10px;
      }
    }
    /deep/ .el-step__head.is-process {
      /deep/ .el-step__icon-inner::before {
        content: "";
        display: block;
        background: #b0babf;
      }
    }
    /deep/ .el-step__head.is-wait {
      /deep/ .el-step__icon-inner::before {
        background: #b0babf;
      }
    }
  }

  .digital-step {
    /deep/ .el-step__description {
      padding: 0 10px !important;
    }
    /deep/ .el-step__head.is-process,
    /deep/ .el-step__head.is-wait {
      /deep/ .el-step__icon {
        border-color: #c0c4cc;
        color: #c0c4cc;
        /deep/ .el-step__icon-inner {
          color: #c0c4cc;
        }
      }
    }
    /deep/ .el-step__title.is-process,
    /deep/ .el-step__title.is-wait {
      color: rgba(102, 102, 102, 1);
      font-weight: 800;
    }
    /deep/ .el-step__title {
      color: rgba(51, 51, 51, 1);
      font-weight: 800;
    }

    /deep/ .el-step__icon {
      width: 35px;
      height: 35px;
      border-color: #00a4ff;
      /deep/ .el-step__icon-inner {
        color: #00a4ff;
      }
    }

    /deep/ .el-step__title {
      color: rgba(51, 51, 51, 1);
    }
  }

  /deep/ .el-step__line {
    top: 16px;
    border-color: #00a4ff;
  }
  .time {
    font-size: 14px;
    color: #333333;
    line-height: 30px;
  }
  .desc-item {
    line-height: 30px;
  }
}
</style>
